<template>
    <div class="agreement">
        <h2>{{info.title}}</h2>
        <div v-html="info.content"></div>
    </div>
</template>

<script>
export default {
    name:'agreement',
    data(){
        return{
            info:{}
        }
    },
    mounted(){
        if(this.$route.query.id){
            this.initFun(this.$route.query.id)
        }else{
            this.initFun(1)
        }
        
    },
    methods:{
        initFun(id){
            var that = this;
            this.$api.POST(this.$face.singleInfo,{id:id},function(res){
                that.info = res.data;
            })
        }
    }
}
</script>

<style scoped>
    .agreement{
        background: #FFF;
    }
    .agreement h2{
        text-align: center;
        font-size: 24px;
        line-height: 60px;
    }
    .agreement>div{
        padding: 0 50px;
    }
    .agreement >>> p{
        font-size: 14px;
        line-height: 22px;
    }
</style>